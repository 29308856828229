.text__container {
  color: aliceblue;
  font-size: 1em;
  width: 50%;
  margin: 0 auto;
  text-align: center;
}
.about__me__image {
  position: absolute;
  bottom: 0px;
  opacity: 1;
  height: 120%;
  left: -60px;
  z-index: -1;
}
.about__me__image {
  bottom: 0;
  right: 80px;
}

.highlight {
  color: var(--grey);
  /* font-weight: bold; */
}
p {
  font-size: 1rem;
  color: var(--grey);
}
@media (max-width: 500px) {
  .about__me__image {
    left: -45px;
  }
}

@media screen and (max-width: 780px) {
  p {
    font-size: 1rem;
    color: white;
  }
}
